<template>
  <div
    class="question__field"
    :name="currentTaskData.id"
  >
    <!-- SIMPLE/MULTIPLE CHOICE -->
    <open-questionnaire-form-item-choice
      v-if="isChoice"
      :question="currentTaskData"
    />

    <!-- RATING -->
    <template v-if="currentTaskIsRating">
      <task-rating />
    </template>

    <!-- OPEN QUESTION -->
    <open-questionnaire-form-item-open-question
      v-if="currentTaskIsOpenQuestion"
    />

    <!-- NPS -->
    <template v-if="currentTaskIsNPS">
      <Task-NPS
        :task="currentTaskData"
        mode="participant"
      />
      <b-notification
        v-if="!formDataIsValid && modified"
        type="is-danger"
        role="alert"
      >
        Debes elegir una opción
      </b-notification>
    </template>

    <div class="question-right-action">
      <span
        v-if="currentTaskData.required"
        class="required-label"
      >
        Obligatoria
      </span>
      <b-button
        v-else
        type="is-light"
        class="clearButton"
        @click="clearAnswers"
      >
        Limpiar respuesta
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import SharedConstants from '@/shared/constants.json'
import OpenQuestionnaireFormItemChoice from './OpenQuestionnaireFormItemChoice.vue'
import OpenQuestionnaireFormItemOpenQuestion from './OpenQuestionnaireFormItemOpenQuestion.vue'
import TaskNPS from './task-nps/TaskNPS.vue'
import TaskRating from './task-rating/TaskRating.vue'

export default {
  name: 'OpenQuestionnaireFormItem',
  components: {
    OpenQuestionnaireFormItemChoice,
    OpenQuestionnaireFormItemOpenQuestion,
    TaskRating,
    TaskNPS
  },

  computed: {
    ...mapGetters('participationOpenQuestionnaire', [
      'currentTaskIsNPS', 'currentTaskIsOpenQuestion', 'currentTaskIsRating',
      'currentTaskIsSimpleChoice', 'currentTaskIsMultipleChoice'
    ]),
    ...mapGetters('participationForm', ['formDataIsValid']),
    ...mapGetters('participationOpenQuestionnaire', ['currentTaskData']),
    ...mapState('participationActivity', ['activityData']),
    ...mapState('participationForm', ['formData', 'modified']),
    ...mapState('participationParticipation', ['stepQuestion', 'participationData']),

    optionSelected() {
      return (
        this.form.optionValue !== ''
        && !Number.isNaN(this.form.optionValue)
        && !this.currentTaskData.required
      )
    },

    isChoice() {
      return this.currentTaskIsSimpleChoice || this.currentTaskIsMultipleChoice
    },

    icon() {
      return this.currentTaskData.icons || SharedConstants.RATING_TASK_ICON.STAR
    }
  },
  methods: {
    clearAnswers() {
      this.$store.commit('participationForm/fillDefaultFormData')
    }
  }
}
</script>

<style lang="scss">
@import "../../scss/openquestionnaireformitem.scss";
</style>
