<template>
  <div v-if="formData">
    <div
      v-if="isResponseWithMedia"
      :class="containerClasses"
    >
      <div
        v-for="n in Array(currentTaskData.responseMediaLimit).keys()"
        :key="n"
        class="media-item"
      >
        <div
          v-if="formData.responseMediaFiles[n] === null || !formData.responseMediaFiles[n].mediaFile"
          class="media-item-container"
        >
          <div class="download-icon">
            <img
              src="../../assets/question/icon-question-media-hover.svg"
              alt="Descargar"
            >
          </div>
          <b-button
            :class="{ 'disabled': cloudinaryWidgetOpen }"
            @click="openMediaWidget($event, n)"
          >
            <div class="button-container">
              <browse-icon />
              <span class="first-word">Explorar</span><span class="second-word">archivos</span>
            </div>
          </b-button>
        </div>
        <div
          v-else
          class="loaded-media"
        >
          <b-button
            class="remove-media"
            @click="removeMedia($event, n)"
          >
            x
          </b-button>
          <media
            :model="formData.responseMediaFiles[n]"
            :media-width="currentTaskData.responseMediaLimit === 1 ? 900 : 280"
          />
        </div>

        <b-input
          v-autogrow
          :value="formData.responseMediaFiles[n] && formData.responseMediaFiles[n].description"
          class="description"
          type="textarea"
          maxlength="200"
          placeholder="Descripción (Opcional)"
          rows="1"
          :has-counter="false"
          @blur="mediaInputChange($event, n)"
        />

        <div
          v-if="formData.responseMediaFiles[n] && formData.responseMediaFiles[n].mediaFile"
          class="refresh-button-container"
        >
          <b-button
            :class="{ 'disabled': cloudinaryWidgetOpen }"
            @click="openMediaWidget($event, n)"
          >
            <div class="button-container">
              <replace-icon />
              <span>Reemplazar</span>
            </div>
          </b-button>
        </div>
      </div>
    </div>
    <div v-else>
      <b-input
        :value="formData.value"
        type="textarea"
        :maxlength="textareaMaxCharacterCount"
        :has-counter="false"
        placeholder="Respuesta libre"
        @input="inputChange"
      />
      <span class="help has-text-right">
        {{ characterCountMessage }}
      </span>
    </div>
    <b-notification
      v-if="displayErrorMessage"
      type="is-danger"
      role="alert"
    >
      {{ isResponseWithMedia ? 'Debes cargar al menos un archivo de media' : 'Debes completar el campo de texto' }}
    </b-notification>
  </div>
</template>

<script>
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive'
import { mapGetters, mapState } from 'vuex'

import BrowseIcon from '@/components/icons/BrowseIcon'
import CloudinaryTexts from '@/cloudinaryTexts.json'
import Media from '@/components/Media.vue'
import ReplaceIcon from '@/components/icons/ReplaceIcon'
import sharedConstants from '@/shared/constants.json'

export default {
  name: 'OpenQuestionnaireFormItemOpenQuestion',
  components: {
    BrowseIcon,
    Media,
    ReplaceIcon
  },
  directives: {
    autogrow: TextareaAutogrowDirective
  },
  data() {
    return {
      cloudinaryWidgetOpen: false
    }
  },
  computed: {
    ...mapGetters('participationForm', ['displayErrorMessage']),
    ...mapGetters('participationOpenQuestionnaire', ['currentTaskData']),
    ...mapState('participationActivity', ['activityData']),
    ...mapState('participationForm', ['formData']),
    ...mapState('participationProject', ['projectData']),

    isResponseWithMedia() {
      return (
        this.currentTaskData.responseAllowedType
          ? this.currentTaskData.responseAllowedType === sharedConstants.TASK_RESPONSE_TYPE.ONLY_MEDIA : null
      )
    },

    containerClasses() {
      const classes = {
        'open-question-with-media': true
      }

      if (this.currentTaskData.responseMediaLimit === 1) {
        classes['one-media-file'] = true
      } else {
        classes['multiple-media-files'] = true
      }

      if (this.cloudinaryWidgetOpen) {
        classes['widget-open'] = true
      }

      return classes
    },

    mediaFolder() {
      const projectName = this.projectData.name.replace(/[^a-zA-Z ]/g, '').substring(0, 30)
      const activityName = this.activityData.name.replace(/[^a-zA-Z ]/g, '').substring(0, 30)
      const taskTitle = this.currentTaskData.title.replace(/[^a-zA-Z ]/g, '').substring(0, 30)

      return 'response-media/'
        + `${this.projectData.identifier}-${projectName}/`
        + `${this.activityData.identifier}-${activityName}/`
        + `${this.currentTaskData.id}-${taskTitle}`
    },

    textareaMinCharacterCount() {
      return this.currentTaskData.responseMinCharacterCount
    },

    textareaMaxCharacterCount() {
      if (this.currentTaskData.responseMaxCharacterCount) {
        return this.currentTaskData.responseMaxCharacterCount
      }

      return sharedConstants.OPEN_QUESTION.DEFAULT_MAX_CHARACTER_COUNT
    },

    characterCountMessage() {
      let message = ''

      if (!this.isResponseWithMedia && this.currentTaskData.required) {
        if (this.textareaMinCharacterCount && this.formData.value.length < this.textareaMinCharacterCount) {
          message = `Mínimo ${this.textareaMinCharacterCount} caracteres`
        } else if (this.textareaMaxCharacterCount && this.formData.value.length >= this.textareaMaxCharacterCount) {
          message = `Máximo ${this.textareaMaxCharacterCount} caracteres`
        }
      }

      return message
    }
  },

  methods: {
    inputChange(value) {
      this.$store.commit('participationForm/updateValue', value)
    },

    mediaInputChange(event, index) {
      this.$store.commit('participationForm/updateResponseMediaFileDescription', { index, value: event.target.value })
    },

    removeMedia(event, index) {
      this.$store.commit('participationForm/removeMedia', { index })
    },

    openMediaWidget(event, index) {
      this.cloudinaryWidgetOpen = true
      window.cloudinary.createUploadWidget({
        upload_preset: 'mvp_preset_participante',
        folder: this.mediaFolder,
        cropping: true,
        multiple: false,
        language: 'es',
        text: CloudinaryTexts,
        showPoweredBy: false,
        sources: [
          'local', 'camera'
        ]
      }, (error, result) => {
        this.enableMediaButton()
        if (!error && result && result.event === 'success') {
          this.setMediaFile(index, result.info)
        }
      }).open()
    },

    enableMediaButton() {
      this.cloudinaryWidgetOpen = false
    },

    setMediaFile(index, mediaInfo) {
      const value = {
        publicId: mediaInfo.public_id,
        width: mediaInfo.width,
        height: mediaInfo.height,
        type: mediaInfo.type,
        resourceType: mediaInfo.resource_type,
        version: mediaInfo.version,
        format: mediaInfo.format
      }

      this.$store.commit('participationForm/updateResponseMediaFileMedia', { index, value })
    }
  }
}
</script>

<style lang="scss" scoped>
.open-question-with-media {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &.widget-open {
    .download-icon {
      opacity: 0.5;
    }
  }

  &.one-media-file {
    .media-item {
      width: 100%;
    }
  }

  &.multiple-media-files {
    .media-item {
      @media (min-width: 1110px) {
        width: calc((100% - 52px) / 3);
        margin-right: 26px;

        &:nth-child(3n+3) {
          margin-right: 0;
        }
      }

      @media (max-width: 1109.98px) {
        width: calc((100% - 26px) / 2);
        margin-right: 26px;

        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }
    }
  }

  .media-item {
    margin-bottom: 16px;

    .media-item-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 42px;
      background: #fafcfd;
      border: 1px dashed rgba(10, 108, 255, 0.2);
      border-radius: 5px;
      margin-bottom: 20px;

      @include respond(mobile) {
        padding: 0px;
        margin-bottom: 12px;
      }

      .download-icon {
        width: 64px;
        height: 64px;
        background: #e9f3fc;
        border-radius: 5.964px;
        padding: 20px 20px;

        @include respond(mobile) {
          display: none;
        }
      }

      button {
        margin: 34px 0px;

        @include respond(mobile) {
          margin: 20px 0px;
        }

        .button-container {
          .first-word {
            margin-right: 5px;
          }

          .second-word {
            @include respond(mobile) {
              display: none;
            }
          }
        }
      }
    }

    .refresh-button-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      @include respond(mobile) {
        margin-top: 10px;
      }
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;

      background: #ffffff;
      border: 1px solid #0a6cff;
      box-sizing: border-box;
      border-radius: 40px;

      /* Inside Auto Layout */

      flex: none;
      order: 0;
      flex-grow: 0;

      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #0a6cff;
      cursor: pointer;

      svg {
        margin-right: 8px;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }

      &:hover {
        background: #0a6cff;
        color: #fff;
      }

      .button-container {
        display: flex;
      }

      @include respond(mobile) {
        font-size: 12px;
      }
    }

    .loaded-media {
      position: relative;

      ::v-deep img {
        display: block;
        width: 100%;
      }

      .remove-media {
        display: none;
        position: absolute;
        top: -15px;
        right: -15px;
        z-index: 9999;
        color: #8f9bb3;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 2px 4px rgba(81, 107, 152, 0.16);
        font-size: 16px;
        padding: 0px;
        border-radius: 20px;
        width: 30px;
        height: 30px;
        line-height: 14px;

        &:hover {
          color: #fff;
          background: #8f9bb3;
        }

        @include respond(mobile) {
          font-size: 14px;
        }
      }
    }

    &:hover {
      .loaded-media {
        .remove-media {
          display: inherit;
        }
      }
    }

    @include respond(mobile) {
      .loaded-media {
        .remove-media {
          display: inherit;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.open-question-with-media {
  .description {
    ::placeholder {
      color: #9b9b9b;
    }
  }

  .textarea {
    height: auto;
    border: none;
    border-bottom: 1px solid #d2d2d2;
    font-size: 16px;
    line-height: 22px;
    color: #9b9b9b;
    resize: none;
    box-shadow: none;
    padding: 0;
    overflow: hidden;
    padding-bottom: 6px;
    border-radius: 0;

    @include respond(mobile) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
