<template>
  <participation-view-content-container
    :current-stage="currentStage"
    :grey-background="true"
  >
    <open-questionnaire />
    <open-questionnaire-navbar
      v-if="isActivityLoaded"
      class="float-bottom-navbar"
    />
  </participation-view-content-container>
</template>

<script>
import { mapState } from 'vuex'
import OpenQuestionnaire from '@/components/open-questionnaire/OpenQuestionnaire.vue'
import OpenQuestionnaireNavbar from '@/components/open-questionnaire/OpenQuestionnaireNavbar.vue'
import ParticipationViewContentContainer from '@/components/participation/ParticipationViewContentContainer.vue'
import participationStages from '@/components/participation/participationStages'

export default {
  name: 'Cuestionario',
  components: {
    OpenQuestionnaire,
    OpenQuestionnaireNavbar,
    ParticipationViewContentContainer
  },
  computed: {
    ...mapState('participationActivity', ['activityData']),

    isActivityLoaded() {
      return this.activityData != null
    }
  },
  created() {
    this.currentStage = participationStages.activity
  }
}
</script>
