<template>
  <div
    v-if="showNavbar"
    class="container is-widescreen"
  >
    <div class="wrapper">
      <div class="columns is-centered">
        <div class="column is-8">
          <div :class="navbarClass">
            <template v-if="currentTaskIdentifier === null">
              <router-link
                v-if="!isAnonymousProject"
                :to="getReturnLink()"
                tag="button"
                class="cancel"
              >
                Volver
              </router-link>
              <button
                class="continue"
                @click="startParticipating"
              >
                Comenzar
              </button>
            </template>
            <template v-else>
              <template v-if="!isAnonymousProject || currentTaskCount > 1 || activityHasDescription">
                <router-link
                  v-if="currentTaskCount === 1 && !activityHasDescription"
                  :to="getReturnLink()"
                  tag="button"
                  class="cancel"
                >
                  Volver
                </router-link>
                <button
                  v-else
                  class="cancel"
                  @click="goToPreviousStep"
                >
                  Anterior
                </button>
              </template>
              <button
                class="button continue"
                :class="{ 'is-loading': isLoadingStep }"
                :disabled="!formDataIsValid"
                @click="submitForm"
              >
                Siguiente
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'OpenQuestionnaireNavbar',
  computed: {
    ...mapGetters(
      'participationActivity',
      ['activityHasDescription', 'activityParticipationComplete', 'currentTaskIsSES', 'getNextTask']
    ),
    ...mapGetters('participationForm', ['formDataIsValid']),
    ...mapGetters('participationOpenQuestionnaire', ['currentTaskCount', 'currentTaskIdentifier']),
    ...mapGetters('participationParticipant', ['participantDisqualified']),
    ...mapGetters('participationProject', ['isAnonymousProject', 'isCommunityProject']),
    ...mapState('participationActivity', ['activityData']),
    ...mapState('participationParticipation', ['updateInProgress']),
    ...mapState('participationProject', ['projectData']),

    isLoadingStep() {
      return this.loadingStep
    },

    showNavbar() {
      return !this.activityParticipationComplete && !this.participantDisqualified
    },
    navbarClass() {
      return {
        'activity-navbar': true,
        'align-right': (
          // Anonymous project on activity description step or 1st question with no description
          this.isAnonymousProject
          && (this.currentTaskCount === 0 || (this.currentTaskCount === 1 && !this.activityHasDescription))
        ),
        'login-required-project': !this.isAnonymousProject
      }
    }
  },
  methods: {
    getReturnLink() {
      if (this.isCommunityProject) {
        return { name: 'project-home', params: { identifier: this.projectData.identifier } }
      }

      return { name: 'proyectos' }
    },

    // Back to previous Step
    goToPreviousStep() {
      if (this.$store.getters['participationOpenQuestionnaire/currentTaskIsSES']) {
        this.$store.dispatch('participationTaskSES/previousStep')
      } else {
        this.$store.dispatch('participationParticipation/goToPreviousStep')
      }
    },

    // Create the participation and set it to IN_PROGRESS
    startParticipating() {
      this.$store.dispatch('participationParticipation/startParticipatingInActivity')
    },

    // Back to previous Step
    async submitForm() {
      if (this.$store.getters['participationOpenQuestionnaire/currentTaskIsSES']) {
        this.$store.dispatch('participationTaskSES/submitForm')
      } else if (this.$store.getters['participationForm/formDataIsValid']) {
        await this.$store.dispatch('participationOpenQuestionnaire/updateParticipationWithFormData')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .wrapper {
    @include respond(mobile) {
      padding: 0px 16px !important;
    }

    .activity-navbar {
      height: 60px;
      background: $white;
      border: 1px solid $gray-ultralight;
      box-shadow: 0px 2px 4px rgba(81, 107, 152, 0.16);
      border-radius: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;

      &.hidden {
        display: none;
      }

      &.align-right {
        justify-content: flex-end;
      }

      button {
        width: 130px;
        height: 35px;
        border-radius: 17.5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        box-sizing: border-box;
        cursor: pointer;
        transition: $buttonBackgroundTransition;
        outline: none;

        @include respond(mobile) {
          width: 120px;
          height: 33px;
        }

        &.cancel {
          background: $white;
          border: 1px solid #2b2323;
          color: $black;
          margin-left: 14px;

          &:hover {
            border: 1px solid $gray;
          }
        }

        &.continue {
          color: $white;
          background: $blue;
          box-shadow: 3px 3px 10px -1px rgba(11, 27, 102, 0.3);
          border: none;
          margin-right: 14px;

          &:hover {
            background-color: $blue-hover;
          }
        }
      }
    }
  }
}
</style>
