<template>
  <!-- eslint-disable -->
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.75 3V7.5H5.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.25 15V10.5H12.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.3675 6.75018C14.9871 5.67527 14.3407 4.71423 13.4884 3.95674C12.6361 3.19925 11.6059 2.67 10.4938 2.41837C9.38167 2.16674 8.22393 2.20093 7.12861 2.51776C6.03328 2.83458 5.03606 3.42371 4.23 4.23018L0.75 7.50018M17.25 10.5002L13.77 13.7702C12.9639 14.5766 11.9667 15.1658 10.8714 15.4826C9.77607 15.7994 8.61833 15.8336 7.50621 15.582C6.3941 15.3304 5.36385 14.8011 4.5116 14.0436C3.65935 13.2861 3.01288 12.3251 2.6325 11.2502" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'ReplaceIcon'
}
</script>
