<template>
  <div class="container is-widescreen extra-top-padding">
    <div class="wrapper">
      <div class="columns is-centered page-bottom-padding-extra">
        <div class="column is-8">
          <b-loading
            v-if="loading || updateInProgress"
            :is-full-page="true"
            :can-cancel="false"
            :active="true"
          />
          <template v-else>
            <template v-if="displayActivity">
              <template v-if="currentTaskData === null">
                <div class="title align-left">
                  {{ activityData.name }}
                </div>
                <div
                  v-if="isCoverImage"
                  class="img-featured"
                >
                  <cld-image
                    cloud-name="relevant"
                    :public-id="mediaPublicId"
                    class="cover"
                    crop="scale"
                    secure="true"
                  >
                    <cld-transformation radius="8" />
                  </cld-image>
                </div>
                <div
                  v-if="activityData.description"
                  class="description activity-description"
                  v-html="activityData.description"
                />
              </template>
              <template v-else>
                <div class="title align-left">
                  Respondé las siguientes preguntas
                </div>
                <TaskSES
                  v-if="isSES"
                  mode="participant"
                />
                <div
                  v-else
                  class="question"
                >
                  <form>
                    <div class="question__title">
                      {{ currentTaskData.title }}
                    </div>

                    <Media
                      :model="currentTaskData"
                      type="question"
                      :media-width="900"
                      v-if="currentTaskData.mediaFile"
                    />

                    <div
                      v-if="questionHasDescription"
                      class="question__description task-description"
                      v-html="currentTaskData.description"
                    />

                    <open-questionnaire-form-item />
                  </form>
                </div>
              </template>
            </template>
            <template v-if="activityParticipationComplete">
              <centered-image-and-text-module
                image="finished-project-participant.png"
                title="¡Muchas gracias por tu participación!"
                :button-text="!isCommunityProject ? 'Conocé más sobre Relevant' : 'Ver mis actividades'"
                :button-link="getCompleteParticipationButtonLink()"
              >
                <div
                  v-if="isCommunityProject"
                  class="show-responses-action"
                >
                  <span>Mirá que contestaron otros participantes y comentá</span>
                  <router-link
                    v-slot="{ href, navigate }"
                    :to="{ name: 'project-interactions-filtered', params: { activityIdentifier, projectIdentifier } }"
                  >
                    <a
                      :href="href"
                      class="response-button"
                      @click="navigate"
                    >
                      Ver respuestas
                    </a>
                  </router-link>
                </div>
              </centered-image-and-text-module>
            </template>
            <centered-image-and-text-module
              v-if="activityParticipationDisqualified"
              image="finished-project-participant-disqualification.png"
              title="¡Muchas gracias por tu participación!"
              button-text="Conocé más sobre Relevant"
              button-link="https://joinrelevant.com"
            >
              Los cupos con tu perfil ya estan completos.<br>
              Encontrá mas información sobre nuestros productos, servicios y beneficios.
            </centered-image-and-text-module>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { CldImage, CldTransformation } from 'cloudinary-vue'
import CenteredImageAndTextModule from '@/components/CenteredImageAndTextModule.vue'
import Media from '@/components/Media.vue'
import sharedConstants from '@/shared/constants.json'
import TaskSES from '@/components/open-questionnaire/task-ses/TaskSES.vue'
import OpenQuestionnaireFormItem from './OpenQuestionnaireFormItem.vue'

export default {
  name: 'OpenQuestionnaire',
  components: {
    CenteredImageAndTextModule,
    CldImage,
    CldTransformation,
    OpenQuestionnaireFormItem,
    Media,
    TaskSES
  },

  data() {
    return {
      // Loading
      loading: true,
      isFullPage: false
    }
  },

  computed: {
    ...mapGetters(
      'participationActivity',
      ['currentTaskIsRating', 'activityParticipationComplete', 'activityParticipationDisqualified']
    ),
    ...mapGetters('participationProject', ['isAnonymousProject', 'isCommunityProject', 'projectIdentifier']),
    ...mapGetters('participationOpenQuestionnaire', ['currentTaskData']),
    ...mapState('participationActivity', ['activityData']),
    ...mapState('participationParticipation', ['participationData', 'openQuestionnaireData', 'updateInProgress']),
    ...mapState('participationProject', ['projectData']),

    activityIdentifier() {
      return this.activityData.identifier
    },

    projectIdentifier() {
      return this.projectData.identifier
    },

    projectTermsExists() {
      return this.projectData.tnc_required
    },

    termsAccepted() {
      if (this.projectTermsExists) {
        return (
          this.projectData.participants[0].project_tnc_accepted_on !== null
          && this.projectData.participants[0].relevant_tnc_accepted_on !== null
        )
      }
      return (
        this.projectData.participants[0].relevant_tnc_accepted_on !== null
      )
    },

    isCoverImage() {
      return this.activityData.coverImage !== null
    },

    mediaPublicId() {
      return this.activityData.coverImage !== null ? this.activityData.coverImage.publicId : null
    },

    questionHasDescription() {
      return this.currentTaskData.description !== ''
    },

    showQuestion() {
      return this.currentTaskData != null && this.participationData
    },

    displayActivity() {
      return !this.activityParticipationComplete && !this.activityParticipationDisqualified && this.activityData != null
    },

    isSES() {
      return this.currentTaskData != null && this.currentTaskData.taskType === sharedConstants.TASK_TYPE.SES
    }
  },

  async created() {
    this.loading = true

    const { projectIdentifier, activityIdentifier } = this.$route.params

    const previousActivityData = { ...this.activityData }

    const fetchResult = await this.$store.dispatch(
      'participationActivity/getActivityData',
      { projectIdentifier, activityIdentifier }
    )

    if (!fetchResult) return // Fetching activity data has failed

    if (previousActivityData.identifier !== this.activityData.identifier) {
      // Activity has changed, reset the Open Questionnaire state
      this.$store.commit('participationOpenQuestionnaire/resetState')
    }

    if (
      (
        this.activityData.participations && this.activityData.participations.length > 0
        && this.activityData.participations[0].status === sharedConstants.PARTICIPATION_STATUS.IN_PROGRESS
      )
      || this.activityData.description === ''
    ) {
      // The participation has already been started, move directly to first task
      await this.$store.dispatch(
        'participationParticipation/startParticipatingInActivity'
      )
    }

    this.loading = false
  },

  methods: {
    getCompleteParticipationButtonLink() {
      if (this.isAnonymousProject) {
        return 'https://joinrelevant.com'
      }

      if (this.isCommunityProject) {
        return { name: 'project-home', params: { identifier: this.projectData.identifier } }
      }

      return { name: 'proyectos' }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/openquestionnaire_scoped.scss";
</style>

<style lang="scss">
.show-responses-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(200, 200, 200, 0.24);
  border-radius: 6px;

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
  }

  .response-button {
    width: 176px;
    margin-top: 10px;
    color: #fff;
    background: #0a6cff;
    border: none;
    height: 44px;
    line-height: 44px;
    border-radius: 22px;
    font-weight: 600;
    font-size: 16px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .2s ease-in-out;
    outline: none;
    padding-left: 32px;
    padding-right: 32px;

    &:hover {
      background-color: #0254d0;
    }
  }
}
</style>
