<template>
  <div class="rating">
    <ul class="list">
      <li
        v-for="(value, index) in maxValues"
        :key="index"
        :class="cssClass(value)"
        class="value"
        :data="index"
        @click="rate(value)"
      >
        <div
          class="icon"
          :class="icon"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'RatingTaskItem',
  props: {
    maxValues: {
      type: Number,
      default: 5
    },
    optionData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('participationForm', ['getFormOptionResponseData']),
    ...mapGetters('participationOpenQuestionnaire', ['currentTaskData']),
    ...mapState('participationForm', ['formData']),
    icon() {
      return this.currentTaskData.icons
    },
    currentValue() {
      const val = this.getFormOptionResponseData(this.optionData.identifier)

      return val === undefined ? 0 : val.integerValue
    }
  },
  methods: {
    rate(value) {
      this.$store.commit(
        'participationForm/updateOptionResponseIntegerValue',
        { optionIdentifier: this.optionData.identifier, value }
      )
    },
    cssClass(value) {
      return { active: value <= this.currentValue }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/rating.scss";
</style>
