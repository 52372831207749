<template>
  <div>
    <div :class="optionsClass">
      <div
        v-for="(option, i) in currentTaskData.options"
        :key="option.identifier"
        :class="optionClass"
      >
        <div
          v-if="hasMedia(i)"
          class="media-component"
        >
          <Media
            :model="option"
            type="option-question"
            :media-width="280"
          />
        </div>
        <div class="input-wrapper">
          <b-radio
            v-if="currentTaskIsSimpleChoice"
            size="is-medium"
            :value="simpleChoiceSelectedOption"
            :native-value="option.identifier"
            @input="simpleChoiceChange"
          >
            {{ option.description }}
          </b-radio>
          <b-checkbox
            v-else
            :value="formData.options.includes(option.identifier)"
            :native-value="option.identifier"
            size="is-medium"
            @input="multipleChoiceChange($event, option.identifier)"
          >
            {{ option.description }}
          </b-checkbox>
          <div
            v-if="option.identifier === 'custom' && showCustomOptionTextInput"
            class="custom-input-container"
          >
            <custom-input
              ref="customOptionTextInputRef"
              :value="formData.value"
              :placeholder="'Por favor especifica'"
              :type="'text'"
              :input="customOptionTextInput"
              :blur="customOptionTextInputBlur"
            />
            <div
              v-if="customOptionInputTextError"
              class="help is-danger"
            >
              Este campo es requerido
            </div>
          </div>
        </div>
        <div
          v-if="option.details"
          class="details"
        >
          {{ option.details }}
        </div>
      </div>
    </div>
    <b-notification
      v-if="false"
      type="is-danger"
      role="alert"
    >
      Debes elegir una opción
    </b-notification>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import CustomInput from '@/components/forms/CustomInput.vue'
import Media from '@/components/Media.vue'

export default {
  name: 'OpenQuestionnaireFormItemChoice',
  components: {
    CustomInput,
    Media
  },
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      customOptionInputTextError: false
    }
  },
  computed: {
    ...mapGetters('participationParticipation', ['currentTaskResponse']),
    ...mapGetters(
      'participationOpenQuestionnaire',
      ['currentTaskData', 'currentTaskIsSimpleChoice', 'currentTaskIsMultipleChoice']
    ),
    ...mapState('participationForm', ['formData']),
    optionsClass() {
      return this.currentTaskData.options.filter((o) => o.mediaFile != null).length > 0 ? 'columns is-multiline' : ''
    },
    optionClass() {
      return this.currentTaskData.options.filter(
        (o) => o.mediaFile != null
      ).length > 0 ? 'column is-one-third field' : 'field'
    },
    optionSelected() {
      return (
        this.formData.optionValue !== ''
        && !Number.isNaN(this.formData.optionValue)
        && !this.currentTaskData.required
      )
    },
    showCustomOptionTextInput() {
      return (
        this.simpleChoiceSelectedOption === 'custom' || this.formData.options.includes('custom')
      )
    },
    simpleChoiceSelectedOption() {
      if (this.currentTaskIsSimpleChoice) {
        if (this.formData.options.length > 0) {
          return this.formData.options[0]
        }
      }

      return null
    }
  },
  methods: {
    customOptionTextInput(value) {
      if (value) {
        // Reset error state
        this.customOptionInputTextError = false
      }

      this.$store.commit('participationForm/updateValue', value)
    },
    customOptionTextInputBlur() {
      if (!this.formData.value) {
        this.customOptionInputTextError = true
      }
    },
    simpleChoiceChange(value) {
      if (value !== 'custom') {
        this.customOptionInputTextError = false
      }

      this.$store.commit('participationForm/updateSimpleChoiceValue', value)

      if (value === 'custom') {
        this.$nextTick(() => {
          this.$refs.customOptionTextInputRef[0].$el.getElementsByTagName('input')[0].focus()
        })
      }
    },
    multipleChoiceChange(event, value) {
      const newOptions = [...this.formData.options]

      if (event) {
        newOptions.push(value)
      } else {
        newOptions.splice(newOptions.indexOf(value), 1)
      }

      this.$store.commit('participationForm/updateOptions', newOptions)

      if (value === 'custom') {
        if (event) {
          this.$nextTick(() => {
            this.$refs.customOptionTextInputRef[0].$el.getElementsByTagName('input')[0].focus()
          })
        } else {
          this.customOptionInputTextError = false
        }
      }
    },
    hasMedia(index) {
      return this.currentTaskData.options[index].mediaFile != null
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-input-container {
  flex-grow: 1;
}
</style>
