<template>
  <div>
    <div
      v-for="optionData in currentTaskData.options"
      :key="optionData.identifier"
      class="field"
    >
      <div class="rating-row">
        <div class="rating-option">
          {{ optionData.description }}
        </div>
        <div class="rating-container">
          <task-rating-item
            :max-values="5"
            :option-data="optionData"
          />
        </div>
      </div>
    </div>
    <b-notification
      v-if="!formDataIsValid"
      type="is-danger"
      role="alert"
    >
      Debes asignarle un puntaje a todas las opciones
    </b-notification>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import TaskRatingItem from './TaskRatingItem.vue'

export default {
  name: 'RatingTask',
  components: {
    TaskRatingItem
  },
  computed: {
    ...mapGetters('participationForm', ['formDataIsValid', 'getOptionResponseData']),
    ...mapGetters('participationOpenQuestionnaire', ['currentTaskData']),
    ...mapState('participationForm', ['formData'])
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/rating.scss";
</style>
