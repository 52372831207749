<template>
  <b-input
    :value="value"
    :placeholder="placeholder"
    type="type"
    class="custom-input"
    @input="input"
    @blur="blur"
  />
</template>

<script>
export default {
  name: 'CustomInput',
  props: {
    blur: {
      type: Function,
      default: () => {}
    },
    input: {
      type: Function,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  .custom-input {
    ::v-deep .input {
      padding: 0;
      box-shadow: none;
      border: 1px solid $button-border-gray;
      border-width: 0 0 1px 0;
      border-radius: 0;
      color: $gray;
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      transition: $buttonBackgroundTransition;
    }
  }
}
</style>
